<template>
  <div id="background" :style="{ backgroundImage: 'url(' + imagepath() + ')'  }">
    <Header color="primary"/>
    <div class="wrapper">
      <div id="content">
        <v-form v-on:submit.prevent="send">
          <v-container :style="{backgroundColor: getBackgroundColor() }">
            <template v-for="(person, i) in persons">
              <v-row justify="center" align="center" :key="'r1+'+i">

                <v-col
                    cols="11"
                    xs="11"
                    sm="10"
                    md="10"
                    lg="10"
                    xl="10"
                >
                  <v-subheader style="font-family: Raleway;">
                    Person {{ i+1 }}
                  </v-subheader>
                  <v-divider></v-divider>
                </v-col>
              </v-row>

            <v-row justify="center" align="center" :key="'r2+'+i">

              <v-col
                  cols="11"
                  xs="11"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
              >
                <v-text-field
                    style="font-family: Raleway;"
                    v-model="person.firstName"
                    dense
                    label="Vorname"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="11"
                  xs="11"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
              >
                <v-text-field
                    style="font-family: Raleway;"
                    v-model="person.lastName"
                    dense
                    label="Nachname"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="11"
                  xs="11"
                  sm="1"
                  md="1"
                  lg="1"
                  xl="1"
              >
                <v-btn
                    style="font-family: Raleway;"
                    v-if="persons.length > 1"
                    color="red"
                    elevation="2"
                    icon
                    @click="deletePerson(i)"
                >
                  <v-icon>{{ icons.mdiMinus }}</v-icon>
                </v-btn>
              </v-col>
              <v-col
                  cols="11"
                  xs="11"
                  sm="1"
                  md="1"
                  lg="1"
                  xl="1"
              >
                <v-btn
                    v-if="i == persons.length-1"
                    color="green"
                    elevation="2"
                    icon
                    @click="addPerson()"
                >
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            </template>

            <v-row justify="center" align="center" >
              <v-col cols="11"
                     xs="11"
                     sm="10"
                     md="10"
                     lg="10"
                     xl="10">
                <v-divider />
                <v-textarea
                    style="font-family: Raleway;" label="Bemerkungen" v-model="notes" outlined counter="500">

                </v-textarea>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">

              <v-col
                  cols="11"
                  xs="11"
                  sm="10"
                  md="10"
                  lg="10"
                  xl="10"
              >
                <v-subheader
                    style="font-family: Raleway;">
                  Kontaktangaben
                </v-subheader>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="11"
                     xs="11"
                     sm="10"
                     md="4"
                     lg="4"
                     xl="4">
                <v-text-field dense
                              label="E-Mail"
                              required
                              style="font-family: Raleway;"
                v-model="email">

                </v-text-field>
              </v-col>
              <v-col cols="11"
                     xs="11"
                     sm="10"
                     md="4"
                     lg="4"
                     xl="4">
                <v-text-field dense
                              label="Mobil"
                              style="font-family: Raleway;"
                              required v-model="phone">

                </v-text-field>
              </v-col>
              <v-col cols="11"
                     xs="11"
                     sm="10"
                     md="2"
                     lg="2"
                     xl="2">
                <v-btn type="submit" outlined width="100%"
                       style="font-family: Raleway;">
                  Anmelden
                </v-btn>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" v-if="isSent()">
              <v-col cols="11"
                     xs="11"
                     sm="10"
                     md="10"
                     lg="10"
                     xl="10" style="font-family: Raleway; text-align: start;" class="green--text" >
                Anmeldung erfolgreich übermittelt. Vielen Dank!
              </v-col>
            </v-row>
            <v-row align="center" justify="center" v-if="isError()">
              <v-col cols="11"
                     xs="11"
                     sm="10"
                     md="10"
                     lg="10"
                     xl="10" style="font-family: Raleway; text-align: start;" class="red--text">
                Bei der Übermittlung der Anmeldung ist ein Fehler aufgetreten. Bitte versuche es später erneut oder melde dich unter +41 79 935 91 82 oder +41 79 266 40 64, danke.
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-dialog v-model="loading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
            <v-layout justify-center align-center>
              <v-progress-circular
                  indeterminate
                  color="primary">
              </v-progress-circular>
            </v-layout>
          </v-container>
        </v-dialog>
      </div>
      <Footer/>
    </div>
  </div>
</template>

<script>
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import sharedfunctions from "../../sharedfunctions";
import { mdiPlus, mdiMinus } from '@mdi/js';
import axios from "axios";

export default {
  name: "Anmeldung",
  components: {Footer, Header},
  data: () => ({
    icons: {
      mdiPlus,
      mdiMinus,
    },
    persons: [
      {firstName: '', lastName: ''}
    ],
    notes: '',
    email: '',
    phone: '',
    sent: false,
    success: true,
    loading: false,
  }),
  methods: {
    isError() {
      return this.sent && !this.success;
    },
    isSent() {
      return this.sent && this.success;
    },
    addPerson() {
      this.persons.push({firstName: '', lastName: ''})
    },
    deletePerson(i) {
      this.persons.splice(i, 1);
    },
    clearInput() {
      this.persons = [{firstName: '', lastName: ''}];
      this.notes = '';
      this.email = '';
      this.phone = '';
    },
    getBackgroundColor() {
      if (this.isMobile()) {
        return 'rgba(201,210,219,0.8)'
      } else {
        return 'rgba(247,251,254,0.8)'
      }
    },
    async send() {
      this.loading = true;

      const formdata = JSON.stringify({persons: this.persons, email: this.email, phone: this.phone, notes: this.notes})


      await axios
          .post("https://sabrinaundsilas-production-d248.up.railway.app/api/anmelden/senden", formdata, {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Content-Type': 'application/json'
            }
          })
          .then((res) => {
            if (res.data == true) {
              this.success = true;
              this.sent = true;
              this.clearInput();
            } else {
              this.success = false;
              this.sent = true;
            }
            this.loading = false;
          })
    },
    imagepath() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return require("../../assets/backgrounds/pexels-ylanite-koppens-6664571.jpg");
        case 'sm':
          return require("../../assets/backgrounds/pexels-ylanite-koppens-6664571.jpg");
        case 'md':
          return require("../../assets/backgrounds/pexels-vie-studio-6168329-turn.jpg");
        case 'lg':
          return require("../../assets/backgrounds/pexels-vie-studio-6168329-turn.jpg");
        case 'xl':
          return require("../../assets/backgrounds/pexels-vie-studio-6168329-turn.jpg");
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    initColorScheme() {
      if (this.isMobile()) {
        this.updateDarkMode(false);
      } else {
        this.updateDarkMode(false);
      }
    },
    // eslint-disable-next-line no-unused-vars
    myEventHandler(e) {
      if (this.isMobile()) {
        this.updateDarkMode(false);
      } else {
        this.updateDarkMode(false);
      }
    }
  },
  beforeMount(){
    this.initColorScheme();
    //this.$vuetify.theme.dark = false;
  },
  created(){
    window.addEventListener("resize", this.myEventHandler);
    this.isMobile = sharedfunctions.isMobile;
    this.updateDarkMode = sharedfunctions.updateDarkMode;
    this.initColorScheme();
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
}
</script>

<style scoped>
#bg{

}
</style>
